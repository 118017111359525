import React, { Component, Fragment } from 'react'
import * as R from 'ramda'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import SEO from '@/components/SEO/SEO'
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer'
import VideoControls from '@/components/VideoControls/VideoControls'
import PostWysiwyg from '@/components/PostWysiwyg/PostWysiwyg'
import Image from '@/components/utils/Image'
import Social from '@/components/Social/Social'
import Tags from '@/components/Tags/Tags'
import RelatedContent from '@/shared/RelatedContent'
import BigPlayPause from '@/components/utils/BigPlayPause'
import Error from '@/components/Error/Error'
import errorIcon from '@/icons/ui-video-error.svg'
import { FadeBox } from '@/animation'
import { setupPage } from '@/utils/common'
import Loader from '@/components/utils/Loader'
import SeriesListing from '@/components/SeriesListing/SeriesListing'
import { PoseGroup } from 'react-pose'

/* eslint-disable */
import {
	relatedVideo,
	relatedBlog,
	relatedAudio,
	optimisedImage
} from '@/queries'
/* eslint-enable */
import {
	pluckPrepItem,
	prepRelatedContent,
	prepDirectoryItem,
	prepSeriesContent,
	getSeoImage
} from '@/utils'

class VideoPost extends Component {
	constructor(props) {
		super(props)

		setupPage(props)
	}

	render() {
		const {
			stop,
			playing,
			data: {
				craft: {
					post,
					related: tmpRelated,
					directory: tmpDirectory,
					series: { edges: tmpSeries }
				}
			},
			pageContext: { next },
			location,
			shouldVideoPlay,
			setVideoPlayState,
			setAudioBarVisibility
		} = this.props

		const { series, episodes } = prepSeriesContent(tmpSeries)

		const { mediaSource, title, subtitle, tags, body, image } = pluckPrepItem([
			post
		])

		const related = R.compose(
			R.take(8),
			prepRelatedContent
		)(tmpRelated)

		const directory = prepDirectoryItem(tmpDirectory)

		const {
			socialMetaTitle,
			socialMetaDescription,
			seoTitle,
			seoDescription
		} = post
		const seoImage = getSeoImage(post)

		return (
			<Fragment>
				<SEO
					title={seoTitle || post.title}
					description={seoDescription || subtitle}
					socialTitle={socialMetaTitle || post.title}
					socialDesc={socialMetaDescription || subtitle}
					image={seoImage}
					url={location.href}
				/>
				<div className="mb-2 pt-4 lg:pt-0">
					<VideoPlayer
						stopAudio={stop}
						className="aspect-16-9 relative w-full"
						isAudioPlaying={playing}
						setAudioBarVisibility={setAudioBarVisibility}
						mediaSource={mediaSource}
						shouldVideoPlay={shouldVideoPlay}
						setVideoPlayState={setVideoPlayState}
						render={({
							showPoster,
							playing,
							playPause,
							error,
							attempts,
							ready,
							isSafari,
							showButton,
							mediaSource: src,
							...rest
						}) => (
							<Fragment>
								{showPoster && (
									<div className="absolute pin dimmer dimmer--image">
										<Image fluid={image} />
									</div>
								)}

								<div
									className={`absolute pin flex items-center justify-center  z-10 ${
										playing && isSafari ? 'pointer-events-none' : ''
									}`}
								>
									<div className="md:text-center md:mb-2 xl:mb-3">
										<PoseGroup>
											{showButton && (
												<FadeBox key="terry">
													<BigPlayPause onClick={playPause} playing={playing} />
												</FadeBox>
											)}
										</PoseGroup>
										{!ready && (
											<div className="h-80 relative opacity-80">
												{!error && <Loader />}
											</div>
										)}
									</div>
								</div>

								{ready && !error && (
									<div>
										{!isSafari && (
											<Fragment>
												<VideoControls
													{...rest}
													playing={playing}
													playPause={playPause}
													next={next}
													id="video-player"
												/>
												<button
													type="button"
													className="absolute pin opacity-0 z-10 w-full h-full block"
													onClick={() => playPause()}
												>
													<span className="visuallyhidden">
														{!playing ? 'Play video' : 'Pause video'}
													</span>
												</button>
											</Fragment>
										)}
									</div>
								)}
								{error && (
									<div className="absolute bg-grey-brd pin h-full flex items-center justify-center w-full">
										<Error
											icon={errorIcon}
											title="uh oh! somethings gone wrong"
											message="please reload the page and try again"
										>
											<p className="text-xxs-fluid mt-1 text-grey-light">
												If that fails, try clicking{' '}
												<a
													target="_blank"
													rel="noopener noreferrer"
													href={src}
													className="text-error hover:text-teal"
												>
													here
												</a>
											</p>
										</Error>
									</div>
								)}
							</Fragment>
						)}
					/>
				</div>
				<div className="border-b border-grey-brd pb-2 mb-2">
					<div className="wrapper mb-4">
						<div className="max-w-800 mx-auto">
							<header className="mb-1">
								<h1 className="text-h1-fluid font-bold mb-0-75">{title}</h1>
								{subtitle && (
									<p className="text-md-fluid text-grey-light">{subtitle}</p>
								)}
							</header>
							<PostWysiwyg>
								<div dangerouslySetInnerHTML={{ __html: body }} />
							</PostWysiwyg>

							<div className="max-w-800 mx-auto mb-4">
								<div className="sm:flex sm:justify-between">
									<div className="mb-1 sm:mb-0">
										<Tags tags={tags} />
									</div>
									<Social title={title} url={location.href} />
								</div>
							</div>
							{series && (
								<div className="w-full mx-auto mb-2">
									<SeriesListing
										episodes={episodes}
										current={post.id}
										track={post}
										title={series.title}
									/>
								</div>
							)}
						</div>
					</div>
				</div>

				<RelatedContent related={related} directory={directory} />
			</Fragment>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({
	player: { setPageType, stop, setAudioBarVisibility, setVideoPlayState }
}) => ({
	setPageType,
	setAudioBarVisibility,
	stop,
	setVideoPlayState
})

export default connect(
	mapState,
	mapDispatch
)(VideoPost)

export const pageQuery = graphql`
	query VideoPostByUri($uri: String!, $searchTags: String!, $id: [Int]!) {
		craft {
			post: entry(uri: $uri) {
				...relatedVideo
			}

			series: entriesConnection(id: $id) {
				edges {
					relatedCategories {
						categories {
							... on Craft_EpisodesCategory {
								title
								episodes {
									... on Craft_Audio {
										title
										uri
										id
										postDate
										duration
										mediaSource
										image {
											... on Craft_ImagesVolume {
												...optimisedImage
											}
										}
									}
									... on Craft_Video {
										title
										uri
										id
										postDate
										duration
										mediaSource
										image {
											... on Craft_ImagesVolume {
												...optimisedImage
											}
										}
									}
								}
							}
						}
					}
				}
			}

			related: tagsConnection(search: $searchTags) {
				edges {
					relatedEntries(
						section: [blog, audio, video]
						limit: 3
						idNot: $id
						order: "postDate desc"
					) {
						entries {
							...relatedBlog
							...relatedAudio
							...relatedVideo
						}
					}
				}
			}

			directory: tagsConnection(search: $searchTags) {
				edges {
					relatedEntries(
						section: [directory]
						order: "postDate desc"
						limit: 8
					) {
						entries {
							... on Craft_Directory {
								title
								id
								address
								telephone
								website
								location
								tags {
									title
									id
								}
								directoryImage {
									... on Craft_DirectoryVolume {
										optimisedDirectory {
											... on Craft_OptimizedImagesData {
												src
												srcset
												srcWebp
												srcsetWebp
												maxSrcsetWidth
												placeholderWidth
												placeholderHeight
												colorPalette
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
