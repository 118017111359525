import React, { Component } from 'react'
import { Link } from 'gatsby'
import ProgressBar from '@/components/ProgressBar/ProgressBar'
import VolumeControls from '@/components/VolumeControls/VolumeControls'
import Duration from '@/components/utils/Duration'
import playIcon from '@/icons/ui-play.svg'
import pauseIcon from '@/icons/ui-pause.svg'
import nextIcon from '@/icons/ui-next.svg'
import soundOn from '@/icons/ui-speaker-on.svg'
import soundOff from '@/icons/ui-speaker-off.svg'

import fullscreenIcon from '@/icons/ui-fullscreen.svg'
import Icon from '@/components/utils/Icon'
import BetterIcon from '@/components/utils/BetterIcon'

import posed from 'react-pose'

const Box = posed.div({
	visible: { opacity: 1 },
	hidden: { opacity: 0 }
})

export default class VideoControls extends Component {
	state = {
		isVisible: true
	}

	timeout = undefined

	componentDidUpdate = ({ playing }) => {
		const { playing: playingNow } = this.props

		if (playing === playingNow) return

		if (playingNow) {
			this.hideControls()
		}
	}

	componentWillUnmount = () => {
		clearTimeout(this.timeout)
	}

	hideControls = () => {
		clearTimeout(this.timeout)
		this.timeout = setTimeout(() => {
			this.setState(() => ({ isVisible: false }))
		}, 3000)
	}

	showControls = () => {
		clearTimeout(this.timeout)
		this.setState(() => ({ isVisible: true }))
	}

	onMouseEnter = () => {
		const { playing } = this.props
		if (playing) {
			this.showControls()
		}
	}

	onMouseLeave = () => {
		const { playing } = this.props
		if (playing) {
			this.hideControls()
		}
	}

	render() {
		const {
			duration,
			played,
			playing,
			playPause,
			muted,
			volume,
			setVolume,
			onSeekMouseDown,
			onSeekChange,
			onSeekMouseUp,
			toggleMuted,
			onClickFullscreen,
			loaded,
			next,
			id,
			wrapper = 'wrapper'
		} = this.props
		const { isVisible } = this.state

		const currentTime = duration * played

		return (
			<Box
				onMouseEnter={this.onMouseEnter}
				onMouseLeave={this.onMouseLeave}
				pose={isVisible ? 'visible' : 'hidden'}
				className="z-20 absolute pin-b pin-l w-full bg-black-60 py-0-5 lg:py-0-75"
			>
				<div className={`${wrapper} flex items-center lg:block`}>
					<div className="mr-1 lg:ml-0-25 lg:mb-0-5 lg:mr-0 flex-1 h-20">
						<ProgressBar
							id={id}
							onMouseDown={onSeekMouseDown}
							onMouseUp={onSeekMouseUp}
							onChange={onSeekChange}
							value={((duration * played) / duration) * 100 || 0}
							currentTime={currentTime}
							loaded={loaded || 0}
							className="text-tundora"
						/>
					</div>
					<div className="flex flex-row-reverse	lg:flex-row flex-wrap items-center justify-start lg:justify-between">
						<div className="flex text-xxs-fluid lg:mb-0-25">
							<button
								type="button"
								aria-label="Play/Pause"
								className="mr-0-75 hidden lg:flex items-center justify-center"
								style={{ width: 25 }}
								onClick={playPause}
							>
								<span className="visuallyhidden">
									{!playing ? 'Play' : 'Pause'}
								</span>
								<BetterIcon
									icon={!playing ? playIcon : pauseIcon}
									className="h-15 w-full"
								/>
							</button>
							{next && (
								<Link
									to={next.uri}
									aria-label=""
									className="mr-0-75 items-center justify-center hidden lg:flex "
								>
									<Icon icon={nextIcon} />
									<span className="visuallyhidden">
										Play next - {next.title}
									</span>
								</Link>
							)}
							<Duration
								className="hidden lg:block"
								seconds={duration * played}
							/>{' '}
							<span className="mr-0-25 ml-0-25 text-aluminium hidden lg:block">
								/
							</span>
							<Duration seconds={duration} />
						</div>

						<div className="flex items-center lg:mb-0-25">
							<div className="hidden lg:block mr-0-75 w-full max-w-150">
								<VolumeControls
									muted={muted}
									id={id}
									onChange={setVolume}
									value={volume * 100}
									className="video-volume"
								/>
							</div>

							<button
								type="button"
								aria-label="Mute"
								className={`hidden lg:block mr-0-75 trans ${
									muted ? 'opacity-50' : ''
								}`}
								onClick={toggleMuted}
							>
								<BetterIcon
									icon={!muted ? soundOn : soundOff}
									className="w-30"
								/>
								<span className="visuallyhidden">
									{!muted ? 'Muted' : 'Unmute'}
								</span>
							</button>
							<button
								type="button"
								aria-label="Fullscreen"
								onClick={onClickFullscreen}
								className="mr-1 lg:mr-0"
							>
								<Icon icon={fullscreenIcon} />
								<span className="visuallyhidden">Fullscreen</span>
							</button>
						</div>
					</div>
				</div>
			</Box>
		)
	}
}
