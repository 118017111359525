import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ProgressBar extends Component {
	static defaultProps = {
		className: ''
	}

	render() {
		const { value, onChange, muted, className = '' } = this.props

		return (
			<label
				htmlFor="volume"
				className={`c-progress trans trans-opacity group relative ${className} ${
					muted ? 'opacity-50' : ''
				}`}
			>
				<span className="visuallyhidden">Volume controls</span>
				<input
					type="range"
					id="volume"
					name="volume"
					min={0}
					max={1}
					step="any"
					onChange={onChange}
					style={{
						'--value': `${value}%`,
						height: '6px'
					}}
				/>
			</label>
		)
	}
}

ProgressBar.propTypes = {
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	muted: PropTypes.bool.isRequired
}
