import React, { Component, createRef } from 'react'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import * as R from 'ramda'

export default class VideoPlayer extends Component {
	player = createRef()

	container = createRef()

	constructor(props) {
		super(props)
		const { mediaSource } = props

		// if (match.length) {

		// 	this.setState({
		// 		attempts: 1,
		// 		mediaSource: src,
		// 		error: false
		// 	})

		// 	return
		// }

		this.state = {
			playing: false,
			volume: 0.2,
			muted: false,
			played: 0,
			duration: 0,
			loop: false,
			showPoster: true,
			ready: false,
			error: false,
			mediaSource,
			attempts: 0,
			hasDom: false,
			showButton: false
			// hover: false
		}
	}

	componentDidMount = () => {
		if (window) {
			this.setState({ hasDom: true })
		}
	}

	load = url => {
		this.setState(() => ({
			url,
			played: 0,
			loaded: 0
		}))
	}

	playPause = () => {
		const { playing } = this.state

		this.setState(() => ({
			playing: !playing
		}))
	}

	stop = () => {
		this.setState(() => ({
			playing: false
		}))
	}

	setVolume = e => {
		const {
			target: { value }
		} = e

		this.setState(() => ({
			volume: parseFloat(value)
		}))
	}

	toggleMuted = () => {
		const { muted } = this.state

		this.setState(() => ({
			muted: !muted
		}))
	}

	onPlay = () => {
		const { isAudioPlaying, stopAudio, setAudioBarVisibility } = this.props

		if (isAudioPlaying) {
			stopAudio()
			setAudioBarVisibility(false)
		}

		this.setState(() => ({
			playing: true,
			showButton: false,
			showPoster: false
		}))
	}

	onPause = () => {
		this.setState(() => ({
			playing: false,
			showButton: true
		}))
	}

	onSeekMouseDown = () => {
		this.setState(() => ({
			seeking: false
		}))
	}

	onSeekChange = e => {
		const {
			target: { value }
		} = e

		this.setState(() => ({
			played: parseFloat(value)
		}))
	}

	onSeekMouseUp = e => {
		const {
			target: { value }
		} = e
		this.setState(() => ({ seeking: false }))

		this.player.current.seekTo(parseFloat(value))
	}

	onProgress = state => {
		const { seeking } = this.state
		// We only want to update time slider if we are not currently seeking
		if (!seeking) {
			this.setState(state)
		}
	}

	onEnded = () => {
		const { loop } = this.state
		this.setState(() => ({ playing: loop }))
	}

	onDuration = duration => {
		this.setState(() => ({ duration }))
	}

	onClickFullscreen = () => {
		screenfull.request(this.container.current.wrapper)
	}

	onReady = () => {
		const { shouldVideoPlay, setVideoPlayState } = this.props

		this.setState({ ready: true, error: false, showButton: true })

		if (shouldVideoPlay) {
			this.playPause()
			// reset the should play value

			setVideoPlayState(false)
		}
	}

	render() {
		const { render, className = '' } = this.props
		const { playing, volume, muted, mediaSource, hasDom } = this.state

		return (
			<div className="relative overflow-hidden w-full" ref={this.container}>
				{hasDom && (
					<ReactPlayer
						ref={this.player}
						className={className}
						width="100%"
						height="100%"
						url={mediaSource}
						playing={playing}
						volume={volume}
						muted={muted}
						onReady={this.onReady}
						onPlay={this.onPlay}
						onPause={this.onPause}
						onEnded={this.onEnded}
						onError={e => {
							console.error(e) // eslint-disable-line no-console
							this.setState({ error: true, showButton: false })
						}}
						onProgress={this.onProgress}
						onDuration={this.onDuration}
					/>
				)}
				{render({
					...this.state,
					toggleMuted: this.toggleMuted,
					playPause: this.playPause,
					setVolume: this.setVolume,
					onSeekMouseDown: this.onSeekMouseDown,
					onSeekMouseUp: this.onSeekMouseUp,
					onSeekChange: this.onSeekChange,
					onClickFullscreen: this.onClickFullscreen
				})}
			</div>
		)
	}
}
